import React, { Component } from 'react';
import { Container } from 'bootstrap-4-react';
/*import CommissionForm from './CommissionForm';*/
import './Contact.css';

export default class Contact extends Component {
  render() {
    return (
      <Container as="contact" role="document" className="contact-form">
        <div className="main-section">
          <h1>Contact Us</h1>
            <h3>Send us an email at <a href="info@vixenvisiondesign.com" title="Email Us">info@vixenvisiondesign.com</a>, visit us on <a href="https://www.instagram.com/vixenvisiondesign" title="Vixen Vision Design Page on Instagram" target="_blank" rel="noopener noreferrer">Instagram</a>, or <a href="https://mailchi.mp/8484db66e5fc/newsletter-signup" title="Sign Up">Sign up for our email newsletter</a>!</h3>
        </div>
        {/* <CommissionForm />*/}
      </Container>
    )
  }
}